import React, { useEffect, useState } from "react";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";
import { FiberManualRecord, PersonRounded } from "@mui/icons-material";
import LoadingBar from 'react-top-loading-bar';
import { Link } from "react-router-dom";

const AllUnstakeHistory = () => {

  const [allDetail, setAllDetail] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [keyCount, setKeycount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchedValue, setSearchedValue] = useState("");
  const [searchedType, setSearchedType] = useState("username");

  useEffect(() => {
    getData();
  }, [ searchedType, searchedValue, limit, page]);

  async function getData() {

    setIsTrue(true);
    setProgress(10);

    let searchData = '';
    if (searchedValue !== "" && searchedValue != undefined) {
      searchData = `&search=${searchedType}&value=${searchedValue}`;
    }

    const Data = await _fetch(`${api_url}report/allUnstakeHistory?${searchData}`, 'GET', {});

    if (Data?.status === "success") {
      setLoading(false);
      setAllDetail(Data?.data?.alldata);
      setTotalData(Data?.total);
      setProgress(100);
      setIsTrue(false);
    }
    if (page > 1) {
      setKeycount(0);
      var count = page - 1;
      setKeycount(count * limit);
    }
  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setPage(newOffset);
  };

  return (
    <>
      <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      />

      <div className={isTrue ? 'loader-bg' : ''}></div>

      <div className="table-sec pb-4">
        <div className="table-sec-subheader">
          <div className="page_info">
            <div className="coustom_container">
              <div className="d-flex align-items-center justify-content-center">

                <h6> <PersonRounded />ReStake History </h6>
                <div className="inner_links_header">

                  <FiberManualRecord className="dot" />
                  <a href="#">Home</a>
                </div>
              </div>
            </div>
          </div>

          {/* user details...  */}
        </div>
        <div className="coustom_container mb-5">
          <div className="table_main table_main_new">
            <div className="heading">
              <h1> ReStaked History </h1>
              <form action="">
                <div className="form-group">
                  <select className="form-select" onChange={(e: any) => setSearchedType(e.target.value)}>
                    <option value={''}>Select Search</option>
                    <option value={'username'}>Username</option>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="search"
                    placeholder="Search"
                    onChange={(e: any) => setSearchedValue(e.target.value)}
                  />
                </div>
              </form> 
            </div>
            <div className="table-sec-new">
              <div className="outer-table table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Username</th>
                      <th scope="col">Activator</th>
                      <th scope="col">Total Package</th>
                      <th scope="col">Type</th>
                      <th scope="col">Activation Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      allDetail?.map((val: any, key: any) => {
                        return (
                          <tr>
                          <td>{page > 1 ? key + keyCount + 1 : key + 1}</td>
                            <td>{val?.username}</td>
                            <td>{val?.activate_by}</td>
                            <td>{val?.package}</td>
                            <td>{val?.type.charAt(0).toUpperCase() + val?.type.slice(1)}</td>
                            <td>{val?.activate_date}</td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
              {totalData > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={totalData / 10}
                previousLabel="<"
              />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllUnstakeHistory;
